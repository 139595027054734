var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"full-width"},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v("\n      "+_vm._s(_vm.$t(
          _vm.newsBlockContent && _vm.newsBlockContent.title
            ? _vm.newsBlockContent.title
            : 'Colfert News'
        ))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"home-news-section-inner relative"},[_c('div',{staticClass:"swiper-slider-wrap"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.newsBlockContent.items),function(item,index){return _c('div',{key:item.title + '-' + index,staticClass:"swiper-slide relative"},[_c('nuxt-link',{attrs:{"to":_vm.replaceLocale(
                  item && item.link && item.link.url ? item.link.url : '/'
                )}},[_c('div',{staticClass:"divider-design"}),_vm._v(" "),_c('picture',{staticClass:"image w-full block"},[_c('source',{attrs:{"media":"(min-width: 767px)","srcset":item && item.image && item.image.desktop
                      ? item.image.desktop
                      : '/homepage/home_news.jpg'}}),_vm._v(" "),_c('img',{staticClass:"w-full h-auto inset absolute",class:_vm.handleImageError ? 'placeholder' : '',attrs:{"src":item && item.image && item.image.mobile
                      ? item.image.mobile
                      : '/homepage/home_news.jpg',"alt":item && item.image && item.image.alt ? item.image.alt : '',"width":"300","height":"200"},on:{"error":_vm.handleImageError}})]),_vm._v(" "),_c('div',{staticClass:"slider-content"},[_c('h3',[_vm._v("\n                  "+_vm._s(_vm.$t(
                      item && item.title
                        ? item.title
                        : 'Testo finto messo qui come titolo '
                    ))+"\n                ")]),_vm._v(" "),_c('p',{staticClass:"slider-desc"},[_vm._v("\n                  "+_vm._s(_vm.$t(item && item.description ? item.description : ''))+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"read-more-date"},[_c('span',{staticClass:"read-more arrow-link"},[_vm._v("\n                    "+_vm._s(_vm.$t(
                        item && item.link && item.link.label
                          ? item.link.label
                          : 'Leggi di più'
                      ))+"\n                    "),_c('ArrowIcon')],1),_vm._v(" "),_c('span',{staticClass:"date"},[_vm._v("\n                    "+_vm._s(_vm.$t(item && item.date ? item.date : '')))])])])])],1)}),0)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }