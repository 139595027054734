<template>
  <section class="full-width">
    <div class="container">
      <h2>
        {{
          $t(
            newsBlockContent && newsBlockContent.title
              ? newsBlockContent.title
              : 'Colfert News'
          )
        }}
      </h2>
      <div class="home-news-section-inner relative">
        <div class="swiper-slider-wrap">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide relative"
              v-for="(item, index) in newsBlockContent.items"
              :key="item.title + '-' + index"
            >
              <nuxt-link
                :to="
                  replaceLocale(
                    item && item.link && item.link.url ? item.link.url : '/'
                  )
                "
                class=""
              >
                <div class="divider-design"></div>
                <picture class="image w-full block">
                  <source
                    media="(min-width: 767px)"
                    :srcset="
                      item && item.image && item.image.desktop
                        ? item.image.desktop
                        : '/homepage/home_news.jpg'
                    "
                  />
                  <img
                    :src="
                      item && item.image && item.image.mobile
                        ? item.image.mobile
                        : '/homepage/home_news.jpg'
                    "
                    :alt="
                      item && item.image && item.image.alt ? item.image.alt : ''
                    "
                    @error="handleImageError"
                    width="300"
                    :class="handleImageError ? 'placeholder' : ''"
                    height="200"
                    class="w-full h-auto inset absolute"
                  />
                </picture>
                <div class="slider-content">
                  <h3>
                    {{
                      $t(
                        item && item.title
                          ? item.title
                          : 'Testo finto messo qui come titolo '
                      )
                    }}
                  </h3>
                  <p class="slider-desc">
                    {{ $t(item && item.description ? item.description : '') }}
                  </p>
                  <div class="read-more-date">
                    <span class="read-more arrow-link">
                      {{
                        $t(
                          item && item.link && item.link.label
                            ? item.link.label
                            : 'Leggi di più'
                        )
                      }}
                      <ArrowIcon />
                    </span>
                    <span class="date">
                      {{ $t(item && item.date ? item.date : '') }}</span
                    >
                  </div>
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import SwiperCore, { Navigation } from 'swiper';
import { replaceLocale } from '~/helpers/common';
import 'swiper/swiper.scss';
import { ArrowIcon } from '~/components/General/Icons';

SwiperCore.use([Navigation]);

export default defineComponent({
  name: 'HomeWork',
  components: {
    ArrowIcon,
  },
  props: {
    newsBlockContent: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      replaceLocale,
    };
  },
  methods: {
    handleImageError(event) {
      event.target.src = '/icons/logo.svg';
    },
  },
});
</script>

<style scoped lang="scss">
.home-news-section {
  width: 100%;
  position: relative;
  background-color: var(--c-grey);
  padding-top: 3.5rem;
  padding-bottom: 4.25rem;
  @include to-tablet-max {
    padding-top: 2rem;
    padding-bottom: 1.5rem;
  }

  h2 {
    color: var(--c-dark-grey);
    text-align: center;
    @include font-italic;
    font-size: var(--font-size-36);
    line-height: var(--line-height-38);
    font-weight: var(--font-weight-medium);
    margin-bottom: 3rem;
    @include to-tablet-max {
      font-size: var(--font-size-24);
      line-height: var(--line-height-24);
      margin-bottom: 2.5rem;
    }
  }
  .swiper-slider-wrap {
    margin: 0 -0.75rem;
    @include to-tablet-max {
      margin: 0;
    }
  }
  .swiper-wrapper {
    display: var(--flex);
    flex-wrap: var(--flex-wrap);
    margin: 0;
  }
  .swiper-slide {
    width: 33.33%;
    position: var(--relative);
    padding: 0 0.75rem;
    margin-bottom: 1.25rem;
    height: auto;
    @include to-tablet-max {
      width: 100%;
      padding: 0;
      margin-bottom: 0.75rem;
    }
    a {
      display: block;
      height: 100%;
      color: var(--c-white);
      box-shadow: 0px 0.25rem 0.25rem 0px rgba(0, 0, 0, 0.25);
      background-color: var(--c-white);
      position: var(--relative);
      .image {
        line-height: 0;
        position: var(--relative);
        padding-bottom: 57.5%;
        overflow: hidden;
        img {
          line-height: 0;
          object-fit: cover;
          transition: transform 0.5s;
          height: 100%;
        }
      }
      &:hover {
        .image {
          img {
            transform: scale(1.02);
          }
        }
      }
    }
    .divider-design {
      height: 1.5rem;
      width: 100%;
      @include to-tablet-max {
        height: 1.25rem;
      }
      &::before {
        background-color: var(--c-yellow);
        min-width: 100%;
      }
    }
    &:after {
      content: '';
      border-right: solid 0.063rem var(--c-white);
      height: var(--h-full);
      position: var(--absolute);
      right: 0;
      top: var(--top-0);
      @include to-mobile-max {
        right: -1rem;
      }
    }
    &:nth-child(3n + 3):after {
      opacity: 0;
    }
    .slider-content {
      background-color: var(--c-white);
      padding: 2rem 1.5rem 3.75rem;
      width: 100%;
      @include to-tablet-max {
        padding: 1rem 0.75rem 1.5rem;
      }
      h3 {
        color: var(--c-dark-grey);
        font-family: var(--lato-regular);
        font-size: var(--font-size-20);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-24);
        margin-bottom: 0;
        @include to-tablet-max {
          font-size: var(--font-size-13);
          line-height: var(--line-height-16);
        }
      }
      .slider-desc {
        color: var(--c-dark-grey);
        font-family: var(--lato-regular);
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-18);
        padding-top: 0.625rem;
        margin-bottom: 0;
        @include to-tablet-max {
          font-size: var(--font-size-13);
          line-height: var(--line-height-16);
          padding-top: 0.625rem;
        }
      }
      .read-more-date {
        position: var(--absolute);
        bottom: 1.5rem;
        left: 1.5rem;
        display: var(--flex);
        justify-content: space-between;
        width: calc(100% - 3rem);
        @include to-tablet-max {
          padding-top: 1.5rem;
          position: var(--relative);
          bottom: unset;
          left: 0;
          width: 100%;
        }
        .date,
        .read-more {
          color: var(--c-dark-grey);
          font-family: var(--lato-regular);
          font-size: var(--font-size-12);
          font-weight: var(--font-weight-medium);
          line-height: var(--line-height-12);
          @include to-mobile-max {
            font-size: var(--font-size-13);
            line-height: var(--line-height-16);
          }
        }
        .date {
          @include to-mobile-max {
            font-size: 0.625rem;
            line-height: var(--line-height-12);
          }
        }
      }
    }
  }
}
</style>
